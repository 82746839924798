import { cloneElement } from 'react'

export * from '@tamagui/lucide-icons'
import { getTextColorVar, TextColor } from '@lyra/core/utils/text'
import type { IconProps as SVGIconProps } from '@tamagui/helpers-icon'
import { useTheme } from 'tamagui'

export * from './DeriveLogo'
export * from './DRVToken'
export * from './Warpcast'
export * from './DiscordIcon'
export * from './TelegramIcon'

export type IconColor = TextColor

export type SVGIcon = React.ReactElement<SVGIconProps>

export type IconProps = {
  icon: SVGIcon
  size?: number | string
  width?: number | string
  height?: number | string
  color?: TextColor | string
  fill?: TextColor
}

export default function Icon({
  icon,
  size,
  width,
  height,
  color: colorInput = 'primary',
  fill,
}: IconProps) {
  const theme = useTheme()
  const textColorVar: string | undefined = getTextColorVar(colorInput as TextColor)
  const color = textColorVar
    ? theme[textColorVar]?.get()
    : colorInput in theme
    ? theme[colorInput]?.get()
    : colorInput
  const props: SVGIconProps = {
    height: height !== undefined ? height : size,
    width: width !== undefined ? width : size,
    color,
  }

  if (fill) {
    props.fill = theme[getTextColorVar(fill)]?.get()
  }
  return cloneElement(icon, props)
}
