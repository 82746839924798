import { Chain, defineChain } from 'viem'
import {
  arbitrum as _arbitrum,
  arbitrumSepolia as _arbitrumSepolia,
  base as _base,
  baseSepolia as _baseSepolia,
  mainnet as _mainnet,
  optimism as _optimism,
  optimismSepolia as _optimismSepolia,
  sepolia as _sepolia,
} from 'viem/chains'

import { Env, env, isMainnet } from './env'
import {
  MAINNET_BLOCK_EXPLORER_URL,
  MAINNET_RPC_URL,
  TESTNET_BLOCK_EXPLORER_URL,
  TESTNET_RPC_URL,
} from './urls'

const ETHEREUM_MAINNET_RPC_URL = isMainnet
  ? 'https://eth-mainnet.g.alchemy.com/v2'
  : 'https://eth-sepolia.g.alchemy.com/v2'
const OPTIMISM_RPC_URL = isMainnet
  ? 'https://opt-mainnet.g.alchemy.com/v2'
  : 'https://opt-sepolia.g.alchemy.com/v2'
const ARBITRUM_RPC_URL = isMainnet
  ? 'https://arb-mainnet.g.alchemy.com/v2'
  : 'https://arb-sepolia.g.alchemy.com/v2'
const BASE_RPC_URL = isMainnet
  ? 'https://base-mainnet.g.alchemy.com/v2'
  : 'https://base-sepolia.g.alchemy.com/v2'

const LYRA_TESTNET_BUNDLER_URL = 'https://bundler-testnet-0f702e10d719.herokuapp.com' // self-hosted bundler
const LYRA_MAINNET_BUNDLER_URL = `https://api.pimlico.io/v1/lyra/rpc?apikey=${process.env.NEXT_PUBLIC_PIMLICO_API_KEY}`

export enum DepositNetwork {
  Ethereum = 'ethereum',
  Arbitrum = 'arbitrum',
  Base = 'base',
  Optimism = 'optimism',
}

const viemMainnet = isMainnet ? _mainnet : _sepolia

const viemArbitrum = isMainnet
  ? _arbitrum
  : {
      ..._arbitrumSepolia,
      blockExplorers: {
        default: {
          name: 'Arbiscan',
          url: 'https://sepolia.arbiscan.io/',
        },
      },
    }
const viemOptimism = isMainnet
  ? _optimism
  : {
      ..._optimismSepolia,
      blockExplorers: {
        default: {
          name: 'Etherscan',
          url: 'https://sepolia-optimism.etherscan.io/',
        },
      },
    }
const viemBase = isMainnet
  ? _base
  : {
      ..._baseSepolia,
      blockExplorers: {
        default: {
          name: 'Basescan',
          url: 'https://sepolia.basescan.org/',
        },
      },
    }

const lyraTestnetChain: Chain = defineChain({
  id: 901,
  name: 'Derive',
  network: 'derive',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethers',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: [TESTNET_RPC_URL],
      webSocket: [TESTNET_RPC_URL.replace('http', 'ws')],
    },
    public: {
      http: [TESTNET_RPC_URL],
      webSocket: [TESTNET_RPC_URL.replace('http', 'ws')],
    },
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: TESTNET_BLOCK_EXPLORER_URL,
    },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  testnet: true,
})

const lyraMainnetChain: Chain = defineChain({
  id: 957,
  name: 'Derive',
  network: 'derive',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethers',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: [MAINNET_RPC_URL],
      webSocket: [MAINNET_RPC_URL.replace('http', 'ws')],
    },
    public: {
      http: [MAINNET_RPC_URL],
      webSocket: [MAINNET_RPC_URL.replace('http', 'ws')],
    },
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: MAINNET_BLOCK_EXPLORER_URL,
    },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  testnet: false,
})

export const mainnetChain: Chain = {
  ...viemMainnet,
  rpcUrls: {
    ...viemMainnet.rpcUrls,
    default: {
      http: [ETHEREUM_MAINNET_RPC_URL],
    },
  },
}

export const optimismChain: Chain = {
  ...viemOptimism,
  rpcUrls: {
    ...viemOptimism.rpcUrls,
    default: {
      http: [OPTIMISM_RPC_URL],
    },
  },
}

export const arbitrumChain: Chain = {
  ...viemArbitrum,
  rpcUrls: {
    ...viemArbitrum.rpcUrls,
    default: {
      http: [ARBITRUM_RPC_URL],
    },
  },
}

export const baseChain: Chain = {
  ...viemBase,
  rpcUrls: {
    ...viemBase.rpcUrls,
    default: {
      http: [BASE_RPC_URL],
    },
  },
}

export const lyraChain = env === Env.Mainnet ? lyraMainnetChain : lyraTestnetChain

export const chains = [lyraChain, mainnetChain, arbitrumChain, baseChain, optimismChain]

export const lyraRpcUrl = env === Env.Mainnet ? MAINNET_RPC_URL : TESTNET_RPC_URL
export const lyraBundlerUrl =
  env === Env.Mainnet ? LYRA_MAINNET_BUNDLER_URL : LYRA_TESTNET_BUNDLER_URL
export const lyraBlockExplorerUrl =
  env === Env.Mainnet ? MAINNET_BLOCK_EXPLORER_URL : TESTNET_BLOCK_EXPLORER_URL
